import React, { useEffect, useRef, useState } from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import { configuration } from "../../helpers/config";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Constants } from "../../helpers/constants";

// Set a debounce threshold for search input
const DEBOUNCE_THRESHOLD = 500;

export default function SearchBox({ transparentInner }) {
  // Local state for managing search functionality
  const [openSearch, setOpenSearch] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState([]);
  const timeoutHandler = useRef(null);
  const ref = useRef(null);

  // Function to handle clicks outside the search box
  function handleOutsideClick() {
    setOpenSearch(false);
  }

  // Custom hook to handle clicks outside the search box
  useOutsideClick(ref, handleOutsideClick);

  // Effect to clear search results when search text is empty
  useEffect(() => {
    if (searchText.length === 0) {
      setSearchValue([]);
    }
  }, [searchText]);
  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);

  function mergeData(models, content) {
    const mergedArray = [];

    // Add models as they are
    models.forEach(model => {
        mergedArray.push({
            // model_name: model.model_name,
            title: model.model_name, // Add title from model name
            page_url: model.page_url,
            page_type: model.page_type
        });
    });

    // Add content, transforming it to match the model structure
    content.forEach(item => {
        mergedArray.push({
            // model_name: item.primary_details.title,
            title: item.primary_details.title, // Add title from content
            page_url: item.primary_details.slug,
            page_type: item.page_type
        });
    });
    return mergedArray;
}

  // Function to handle search input change
  // const onChange = async (e) => {
  //   setSearchText(e.target.value);
  //   if (e.target.value.length >= 3) {
  //     if (timeoutHandler.current) {
  //       clearTimeout(timeoutHandler.current);
  //     }
  //     timeoutHandler.current = setTimeout(async () => {
  //       try {
  //         const res = await axios.get(
  //           `${configuration.APP_URL_CONTENTS}/search/${e.target.value}?lob=${LOB_VALUE}`,
  //         );
  //         const res2 = await axios.get(
  //           `${configuration.APP_URL_PRODUCTS}search/${e.target.value}`,
  //         );
  //         const data  = mergeData(res2.data, res.data);
  //         if (data.length > 0) {
  //           setOpenData(true);
  //         }
  //         setSearchValue(data);
  //       } catch (err) {
  //         // console.warn(err, "Error Fetching Preview Data");
  //       }
  //     }, DEBOUNCE_THRESHOLD);
  //   }
  // };

  const onChange = async (e) => {
    if (!LOB_VALUE) return; // Ensure LOB_VALUE is available
  
    const { value } = e.target;
    setSearchText(value);
  
    if (value.length >= 3) {
      if (timeoutHandler?.current) {
        clearTimeout(timeoutHandler.current);
      }
  
      timeoutHandler.current = setTimeout(async () => {
        try {
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/search?title=${value?.toString()}&lob=${LOB_VALUE}`
          );
          const res2 = await axios.get(
                      `${configuration.APP_URL_PRODUCTS}search?title=${value.toString()}`,
                    );
        const data  = mergeData(res2.data, res.data);
  
          if (data?.length > 0) {
            setOpenData(true);
          }
          setSearchValue(data);
        } catch (err) {
          console.log("Error Fetching Preview Data:", err);
        }
      }, DEBOUNCE_THRESHOLD);
    }
  };

  // Function to handle opening/closing the search box
  const openSearchHandler = () => {
    if (!openSearch) {
      setOpenSearch(true);
    } else {
      setOpenSearch(false);
      setSearchValue([]);
      setSearchText("");
    }
  };

  return (
    <div className="relative">
      <div
        ref={ref}
        className={
          openSearch
            ? "active-search " + "searchbox-container"
            : "searchbox-container"
        }
        // onBlur={onSearchBlur}
      >
        <input
          type="text"
          id="searchInput"
          className="searchInput"
          // onBlur={onSearchBlur}
          placeholder={Constants.layout.TYPE_HERE}
          value={searchText}
          onChange={onChange}
        />
        <button
          type="button"
          className="p-1 pl-2 focus:outline-none"
          onClick={openSearchHandler}
          // onClick={() => setOpenSearch(() => !openSearch)}
        >
          <span className="sr-only">{Constants.layout.VIEW_NOTIFICATION}</span>
          <img
            className=" w-[17px] h-[17px]"
            id="crossIcon"
            src={`${configuration.IMG_STATIC_URL}assets/images/cross.svg`}
            alt="cross-icon"
          />
        </button>
        {/* { openData ? */}
        <div
          className={
            "search-dropdown absolute top-[30px] w-[320px] left-0 bg-[#fff]"
          }
          id="searchDropdown"
          style={{ boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)" }}
        >
          <ul id="searchDropdownList">
            {searchValue.length > 0 &&
              searchValue?.map((item, index) => (
                <li
                  key={index}
                  // onClick={() => searchDataByType(item.page_type , item.page_url)}
                  className="p-[12px] cursor-pointer border-b-[1px] border-[#e0e0e0]"
                  id="searchDropdownListItem"
                >
                  <a
                    className="text-[13px]"
                    rel="noreferrer"
                    href={
                      item.page_type == "Models"
                        ? `/cars/${item.page_url}`
                        : item.page_type == "Content"
                        ? `/pages/${item.page_url}`
                        : `/blogs/${item.page_url}`
                    }
                  >
                    {item.title.length > 35
                      ? item.title.slice(0, 35) + "... "
                      : item.title}{" "}
                    <strong>
                      {" "}
                      in {item.page_type == "Models" ? "Model" : item.page_type == "Content" ? "Content" :"Blogs"}{" "}
                    </strong>
                    {/* {item.page_title} */}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        {/* : ''} */}
      </div>
      <button
        type="button"
        className="p-1 pl-2 ml-2 focus:outline-none"
        id="globalSearchIcon"
        onClick={openSearchHandler}
        // onClick={() => setOpenSearch(() => !openSearch)}
      >
        <span className="sr-only">{Constants.layout.VIEW_NOTIFICATION}</span>
        <img
          className={`${
            transparentInner ? "invert" : "invert"
          } w-[17px] h-[17px]`}
          src={`${configuration.IMG_STATIC_URL}assets/images/header/search.svg`}
          alt="search-icon"
        />
      </button>
    </div>
  );
}
